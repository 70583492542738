import { Injectable, OnInit } from '@angular/core';
import { Plot } from '../class/plot';
import { Image } from '../class/image';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import * as _ from 'underscore';

@Injectable()
export class PlotService  {
  private plotList: Plot[] = [];
  private plotSubj$: BehaviorSubject<Plot>;
  constructor() {
    this.createPlotList();
    this.plotSubj$ = new BehaviorSubject<Plot>(null);
  }

  public subscribeToPlot(): Observable<Plot> {
    return this.plotSubj$.asObservable().pipe(share());
  }

  public getPlotList(): Plot[] {
    return this.plotList;
  }

  public getPlot(plotId: number): Plot {
    return _.find(this.plotList, o => o.plotId === plotId);
  }

  public setPlot(plotId: number) {
    this.plotSubj$.next(this.getPlot(plotId));
  }

  private createImagePlot1(): Image[] {
    const imgList: Image[] = [];
    const img1  = new Image('../../../assets/images/plot1/stolsvikahouse.jpg' , 'Huset i Stølsvika');
    const img2  = new Image('../../../assets/images/plot1/stolsvikaoverview.jpg' , 'Oversikt over Stølsvika');
    imgList.push(img1);
    imgList.push(img2);
    return imgList;
  }

  private createPlotMapFilename(plotNr: number): Image {
    return new Image('../../../assets/images/plot1/mapplot' + plotNr + '.jpg', 'Kart over tomt ' + plotNr);
  }

  private createPlotList() {
    this.plotList.push(new Plot(1, 'Tomt 1', null, this.createPlotMapFilename(1), 0,
      1133, this.createImagePlot1(), 'Dette er en beskrivelse av tomten, utsikt, sjølinje, etc'));
    this.plotList.push(new Plot(2, 'Tomt 2', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(3, 'Tomt 3', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(4, 'Tomt 4', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(5, 'Tomt 5', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(6, 'Tomt 6', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(7, 'Tomt 7', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(8, 'Tomt 8', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(9, 'Tomt 9', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(10, 'Tomt 10', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(11, 'Tomt 11', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(12, 'Tomt 12', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(13, 'Tomt 13', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(14, 'Tomt 14', null, null, 0, 1010, null, ''));
    this.plotList.push(new Plot(15, 'Tomt 15', null, null, 0, 1010, null, ''));
  }
}
