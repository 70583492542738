import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapComponent } from './components/map/map.component';
import { InfoComponent } from './components/info/info.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ContactComponent } from './components/contact/contact.component';
import { PageEnum } from './enum/pageenum';
import { PlotService} from './service/plot.service';
import {Plot} from './class/plot';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  private pageNr: number;
  private plotNr: number;
  public plotList: Plot[] = [];
  public selectedPlot: Plot;

  constructor(
    private ngZone: NgZone,
    private plotService: PlotService
  ) {
    this.plotList = this.plotService.getPlotList();
  }

  ngOnInit() {
    this.pageNr = 1;
  }

  isPage(thisPage: number): boolean {
    return (thisPage === this.pageNr);
  }

  setPage(thisPage: number) {
    this.pageNr = thisPage;
  }

  setPagePlot(thisPlot: number) {
    this.pageNr = 2;
    this.plotService.setPlot(thisPlot);
    this.plotNr = thisPlot;
  }

  getPlot(): number {
    return this.plotNr;
  }
}
