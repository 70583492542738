import {Component, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PlotService } from '../../service/plot.service';
import { Plot } from '../../class/plot';
import {Observable} from 'rxjs';
import {Image} from '../../class/image';
declare var require: any

@Component({
  selector: 'app-plot',
  templateUrl: './plot.component.html',
  styleUrls: ['./plot.component.css']
})
export class PlotComponent implements OnInit {
  public plotNr: number;
  public selectedPlot: Plot;
  public selectedImage: Image;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private plotService: PlotService) {
    this.selectedPlot = this.plotService.getPlot(1);
    this.selectedImage = this.getImage(0);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.plotNr = Number(params['id']);
      if (this.plotNr) {
        this.selectedPlot = this.plotService.getPlot(this.plotNr);
        this.selectedImage = this.getImage(0);
      }
    });

    this.plotService.subscribeToPlot()
      .subscribe(plot => {
        if (plot) {
          this.selectedPlot = plot;
        }
      });
  }

  public getImage(imgId: number): Image {
    if (this.selectedPlot && this.selectedPlot.imageList && imgId < this.selectedPlot.imageList.length) {
      return this.selectedPlot.imageList[imgId];
    }
    return null;
  }

  public getPlotMap(): Image {
    if (this.selectedPlot && this.selectedPlot.plotMapFile) {
      return this.selectedPlot.plotMapFile;
    }
    return null;
  }

  public setDisplayImage(imgNumber: number) {
    this.selectedImage = this.getImage(imgNumber);
  }

  public setPlotMap() {
    this.selectedImage = this.selectedPlot.plotMapFile;
  }

  public getDisplayImage(): Image {
    return this.selectedImage;
  }

  public getPrice(price: number): string {
    if (price === 0) {
      return 'På forespørsel';
    }
    const pr: string = price as any as string;
    return pr;
  }
}
