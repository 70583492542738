import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
// import { } from '@types/googlemaps';
import { GoogleMapsModule } from '@angular/google-maps';
// import { } from 'googlemaps';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit  {
  public map: google.maps.Map;

  lat = 61.179491;
  lng = 4.866596;
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 17,
    mapTypeId: google.maps.MapTypeId.SATELLITE
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });

  // @ViewChild('mapContainer') gmap: ElementRef;
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;

  constructor(
    private ngZone: NgZone
  ) {}

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);
    this.marker.setMap(this.map);
  }
}
