import { Image } from './image';

export class Plot {

  public constructor(plotId: number, plotName: string, plotImageFiles: string[], plotMapFile: Image,
                     plotPrice: number, plotSqm: number, imageList: Image[], plotDesc: string) {
    this.plotId = plotId;
    this.plotName = plotName;
    this.plotImageFiles = plotImageFiles;
    this.plotMapFile = plotMapFile;
    this.imageList = imageList;
    this.plotSqm = plotSqm;
    this.plotPrice = plotPrice;
    this.plotDesc = plotDesc;
  }
  plotId: number;
  plotName: string;
  plotImageFiles: string[];
  plotMapFile: Image;
  plotPrice: number;
  plotSqm: number;
  imageList: Image[];
  plotDesc: string;
}
